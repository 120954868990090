import React from 'react';

const InquiryUndeterminedOutcome = ({ isAdditionalTrack }) => {
  return (
    <>
      <div className="InquiryOutcomeEstimateUpToAmountWrapper">
        <h1>We need a bit more info to get your estimate</h1>
      </div>
      <div className="InquiryOutcomeEstimateSubTextContainer">
        Create an account to finish your Investment application — it only takes 5 minutes, and we’ll have all the info
        we need to tell you how much equity you can access.{' '}
        {isAdditionalTrack &&
          'You can also get started on your Application - it takes less than 5 minutes to complete!'}
        {!isAdditionalTrack && (
          <div className="InquiryOutcomeEstimateSubText">
            Not sure, yet? Your Investment Manager (and our tools) will help you compare financing options to find one
            that fits your needs.
          </div>
        )}
      </div>
    </>
  );
};

export default InquiryUndeterminedOutcome;

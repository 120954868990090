import React from 'react';
import { Button } from '@hometap/htco-components';
import { INQUIRY_SIGN_IN_URL } from '../../../constants/inquiryConstants';
import { getDashboardUrl } from 'utils/links';

const InquiryGetStartedButton = ({ isAdditionalTrack }) => (
  <>
    {isAdditionalTrack ? (
      <Button href={getDashboardUrl()}>Return to dashboard</Button>
    ) : (
      // TODO(JAKE-425): Update this button to redirect to the correct URL
      <>
        <Button href={INQUIRY_SIGN_IN_URL} className="InquirySignInButton">
          Get started
        </Button>
      </>
    )}
  </>
);

export default InquiryGetStartedButton;

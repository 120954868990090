const PROPERTY_URL = '/inquiry/info/property/';
const HOMEOWNER_URL = '/inquiry/info/property/';
const SSN_URL = '/inquiry/data/ssn/';
const SUCCESSFUL_OUTCOME_URL = '/inquiry/submitted/';
const UNSUCCESSFUL_OUTCOME_URL = '/inquiry/results/';
const UNSUCCESSFUL_OUTCOME_FORM_SUBMITTED_URL = '/inquiry/subscribed/';
const UNSUCCESSFUL_OUTCOME_RISK_BAND_AA = UNSUCCESSFUL_OUTCOME_URL;
const UNSUCCESSFUL_OUTCOME_FICO_SCORE_AA = UNSUCCESSFUL_OUTCOME_URL;
export const INQUIRY_SIGN_IN_URL = '/inquiry/info/sign-in/';

export const STEP_NAMES = {
  PROPERTY: 'property',
  HOMEOWNER: 'homeowner',
  SSN: 'ssn',
  SUCCESSFUL_OUTCOME: 'successfulOutcome',
  UNSUCCESSFUL_OUTCOME: 'unsuccessfulOutcome',
  UNSUCCESSFUL_OUTCOME_SUBMITTED: 'unsuccessfulOutcomeSubmitted',
  UNSUCCESSFUL_OUTCOME_RISK_BAND_AA: 'unsuccessfulOutcomeRiskBandAA',
  UNSUCCESSFUL_OUTCOME_FICO_SCORE_AA: 'unsuccessfulOutcomeFicoScoreAA',
};

export const STEPS = {
  [STEP_NAMES.PROPERTY]: PROPERTY_URL,
  [STEP_NAMES.HOMEOWNER]: HOMEOWNER_URL,
  [STEP_NAMES.SSN]: SSN_URL,
  [STEP_NAMES.SUCCESSFUL_OUTCOME]: SUCCESSFUL_OUTCOME_URL,
  [STEP_NAMES.UNSUCCESSFUL_OUTCOME]: UNSUCCESSFUL_OUTCOME_URL,
  [STEP_NAMES.UNSUCCESSFUL_OUTCOME_SUBMITTED]: UNSUCCESSFUL_OUTCOME_FORM_SUBMITTED_URL,
  [STEP_NAMES.UNSUCCESSFUL_OUTCOME_RISK_BAND_AA]: UNSUCCESSFUL_OUTCOME_RISK_BAND_AA,
  [STEP_NAMES.UNSUCCESSFUL_OUTCOME_FICO_SCORE_AA]: UNSUCCESSFUL_OUTCOME_FICO_SCORE_AA,
};

const TRACKING_DATA = [
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_term',
  'referrer',
  'referring_domain',
  'transaction_id',
  'referralCode',
];

export const INQUIRY_CREATED_PREFIX_PROPERTIES = [...TRACKING_DATA, 'gclid', 'fbclid', 'ttclid', 'offer_id'];

export const INQUIRY_CONVERTED_PREFIX_PROPERTIES = [...TRACKING_DATA];

export const INQUIRY_CREATED_TRACKING_PROPERTIES = [
  ...TRACKING_DATA,
  'gclid',
  'fbclid',
  'ttclid',
  'offer_id',
  'marketing_site_user_properties',
];

export const INQUIRY_CONVERTED_TRACKING_PROPERTIES = [
  ...TRACKING_DATA,
  'gclid',
  'fbclid',
  'ttclid',
  'external_id',
  'event_id',
];

export const EQUITY_DASHBOARD_TRACKING_DATA = [...TRACKING_DATA, 'gclid', 'fbclid', 'ttclid', 'offer_id'];

export const FORM_FIELDS = {
  ZIP_CODE: 'zip_code',
  CITY: 'city',
  STREET: 'street',
  STATE: 'state',
  UNIT: 'unit',
  RESIDENCE_TYPE: 'residence_type',
  PROPERTY_TYPE: 'property_type',
  MORTGAGE_BALANCE: 'mortgage_balance',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  PRIMARY_USE_CASE: 'primary_use_case',
  WHEN_INTERESTED: 'when_interested',
  CONSENT_GIVEN: 'consent_given',
  SSN: 'ssn',
  BIRTH_DATE: 'birth_date',
};

export const PROPERTY_FORM_FIELDS = [
  FORM_FIELDS.ZIP_CODE,
  FORM_FIELDS.CITY,
  FORM_FIELDS.STREET,
  FORM_FIELDS.STATE,
  FORM_FIELDS.UNIT,
  FORM_FIELDS.RESIDENCE_TYPE,
  FORM_FIELDS.PROPERTY_TYPE,
  FORM_FIELDS.MORTGAGE_BALANCE,
];

export const HOMEOWNER_FORM_FIELDS = [
  FORM_FIELDS.FIRST_NAME,
  FORM_FIELDS.LAST_NAME,
  FORM_FIELDS.EMAIL,
  FORM_FIELDS.PHONE_NUMBER,
  FORM_FIELDS.PRIMARY_USE_CASE,
  FORM_FIELDS.WHEN_INTERESTED,
];

export const INQUIRY_USER_FIELDS = [
  FORM_FIELDS.FIRST_NAME,
  FORM_FIELDS.LAST_NAME,
  FORM_FIELDS.EMAIL,
  FORM_FIELDS.BIRTH_DATE,
];

export const TRUST_PILOT_SCRIPT = (
  <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
);

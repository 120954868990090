import React, { useState } from 'react';
import { Button, Grid, useWindowSize } from '@hometap/htco-components';
import { PropertyLocationFailHeader, RiskFailHeader } from './outcomeTextComponents';
import InquiryRiskBand from './InquiryRiskBand';
import { DQ_PAGES } from 'apps/progressive-inquiry/constants/progressiveInquiryConstants';
import useCurrentUser from 'hooks/useCurrentUser';
import HedDqCta from './HedDqCta';
import './InquiryDQ.scss';

const InquiryDQ = ({ step, firstName, lastName, riskFailFactors, ficoScore, experianDataPulledAt }) => {
  const [stepDQ, setStepDQ] = useState(step);
  const { isScreenSize } = useWindowSize();
  const { user } = useCurrentUser();

  const InquiryDisclosure = () => {
    const onBack = () => {
      return stepDQ === DQ_PAGES.RISK_AA ? setStepDQ(DQ_PAGES.RISK) : setStepDQ(DQ_PAGES.FICO);
    };

    return (
      <>
        {stepDQ === DQ_PAGES.RISK_AA || stepDQ === DQ_PAGES.FICO_AA ? (
          <InquiryRiskBand
            firstName={firstName}
            lastName={lastName}
            experianDataPulledAt={experianDataPulledAt}
            ficoScore={ficoScore}
            riskFailFactors={riskFailFactors}
            onBack={onBack}
            ficoFail={stepDQ === DQ_PAGES.FICO_AA}
          />
        ) : null}
      </>
    );
  };

  const InquiryDQPage = () => {
    switch (stepDQ) {
      case DQ_PAGES.LOCATION:
      case DQ_PAGES.COOP:
      case DQ_PAGES.MOBILE_HOME:
        return (
          <div className="InquiryDQBodyContainer">
            <PropertyLocationFailHeader reason={stepDQ} />
            <InquiryDQBody />
          </div>
        );

      case DQ_PAGES.RISK:
      case DQ_PAGES.FICO:
        return (
          <div className="InquiryDQBodyContainer">
            <RiskFailHeader />
            <InquiryDQBody />
          </div>
        );
      default:
        return null;
    }
  };

  const InquiryDQBody = () => {
    return (
      <>
        <p className="InquiryDQBody" data-testid="inquiry-dq-row-1">
          But our free tools can help you understand and make the most of your home finances.
        </p>
        {stepDQ === DQ_PAGES.RISK || stepDQ === DQ_PAGES.FICO ? (
          <>
            <p className="InquiryDQBody" data-testid="inquiry-dq-row-2">
              We consider a combination of factors when making investments, including information from a credit
              reporting agency.
            </p>
            <Button
              theme="link"
              className="DQLink"
              onClick={() => setStepDQ(stepDQ === DQ_PAGES.RISK ? DQ_PAGES.RISK_AA : DQ_PAGES.FICO_AA)}
              dataTestId="dq-fcra-link"
            >
              Learn about the Fair Credit Reporting Act
            </Button>
          </>
        ) : null}
        <Button
          className="InquiryDQExit"
          href={user ? '/dashboard' : 'https://hometap.com/'}
          data-testid="dq-exit-link"
        >
          Exit inquiry
        </Button>
      </>
    );
  };

  const hideCtaSection = (stepDQ === DQ_PAGES.RISK_AA || stepDQ === DQ_PAGES.FICO_AA) && isScreenSize('sm');

  return (
    <Grid className="InquiryDQ" container>
      <Grid sm={12} md={6} className="InquiryDQContentPanel">
        {InquiryDQPage()}
        {InquiryDisclosure()}
      </Grid>
      {!hideCtaSection && (
        <Grid sm={12} md={6} className="InquiryDQHedCta">
          <HedDqCta />
        </Grid>
      )}
    </Grid>
  );
};

export default InquiryDQ;

import React from 'react';
import {
  CardGroup,
  PointCard,
  useWindowSize,
  TestimonialsCarousel,
  Paper,
  Banner,
  Icon,
} from '@hometap/htco-components';
import { Helmet } from 'react-helmet-async';
import md5 from 'md5';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useCurrentUser from 'hooks/useCurrentUser';
import useSearchParams from 'hooks/useSearchParams';
import InquiryOutcomeFAQ from './successfulOutcomes/InquiryOutcomeFAQ';
import TrustPilot from '../TrustPilot';
import InquirySuccessfulEstimateOutcome from './successfulOutcomes/InquirySuccessfulEstimateOutcome';
import InquiryUndeterminedOutcome from './successfulOutcomes/InquiryUndeterminedOutcome';
import InquirySuccessfulCTAOutcome from './successfulOutcomes/InquirySuccessfulCTAOutcome';
import InquiryUndeterminedCTAOutcome from './successfulOutcomes/InquiryUndeterminedCTAOutcome';
import InquiryCTAOutcomeButton from './successfulOutcomes/InquiryCTAOutcomeButton';
import InquiryGetStartedButton from './successfulOutcomes/InquiryGetStartedButton';
import { PREQUAL_OUTCOME_CODES } from '../../constants/APIConstants';
import AudacyPixel from 'components/AudacyPixel/AudacyPixel';
import env from 'utils/env';

const InquiryOutcomeSuccessful = ({
  upToAmount,
  name,
  email,
  resentMagicLink,
  onClickResendMagicLink,
  isAdditionalTrack,
  isPartnerOutcome,
  outcomeCode,
  inquiryId,
  utmSource,
}) => {
  const { isScreenSize } = useWindowSize();
  const query = useSearchParams();
  const outcomeKey = query.get('key') || outcomeCode;
  const investmentAmount = query.get('investment_amount');
  const undeterminedOutcome = outcomeKey === PREQUAL_OUTCOME_CODES.UNDETERMINED;
  const emailHash = md5(email || 'unknown');
  const { user } = useCurrentUser();
  const isLoggedIn = !!user;
  const flags = useFlags();
  // if it's a partner outcome or they're logged in we want to use the code not flagged with showInquirySignInMethods
  const showInquirySignInMethods = flags.showInquirySignInMethods && !isPartnerOutcome && !isLoggedIn;

  return (
    <div className="InquiryOutcome">
      <Helmet bodyAttributes={{ class: 'isLightBackground' }} title="Estimate | Investment Application | Hometap Hub">
        {/* LiveConnect Event Tag */}
        <script type="text/javascript">
          {`
          window.liQ = window.liQ || [];
          window.liQ.push({
              "event": "conversion",
              "name": "email_signup",
              "emailHash": "${emailHash}"
          });
        `}
        </script>

        {/* tvScientific script */}
        {env.isProd() && inquiryId && utmSource && (
          <script type="text/javascript">
            {(function () {
              // eslint-disable-next-line
              var p, s, d, w;
              d = document;
              w = window.location;
              p = d.createElement('IMG');
              // eslint-disable-next-line
              s =
                // eslint-disable-next-line
                w.protocol +
                '//tvspix.com/t.png?&t=' +
                new Date().getTime() +
                '&l=tvscientific-pix-o-92deb6b2-a1c1-40f1-8dd6-03381eeedad7&u3=' +
                encodeURIComponent(w.href) +
                `&u1=complete_registration&u4={${inquiryId}}&u5={${utmSource}}`;
              p.setAttribute('src', s);
              p.setAttribute('height', '0');
              p.setAttribute('width', '0');
              p.setAttribute('alt', '');
              p.style.setProperty('display', 'none');
              p.style.setProperty('position', 'absolute');
              p.style.setProperty('visibility', 'hidden');
              d.body.appendChild(p);
            })()}
          </script>
        )}
      </Helmet>
      {resentMagicLink && (
        <Banner className="InquiryOutcomeBanner">
          We’ve sent a magic link to {email}. Please note, this link will expire after 24 hours.
        </Banner>
      )}

      <div className="InquiryOutcomeWrapper">
        <div className="InquiryOutcomeContainer">
          {showInquirySignInMethods &&
            (undeterminedOutcome ? (
              <InquiryUndeterminedOutcome isAdditionalTrack={isAdditionalTrack} />
            ) : (
              <InquirySuccessfulEstimateOutcome
                isAdditionalTrack={isAdditionalTrack}
                upToAmount={upToAmount}
                name={name}
                isPartnerOutcome={isPartnerOutcome}
                investmentAmount={investmentAmount}
              />
            ))}
          {!showInquirySignInMethods &&
            (undeterminedOutcome ? (
              <InquiryUndeterminedCTAOutcome isAdditionalTrack={isAdditionalTrack} />
            ) : (
              <InquirySuccessfulCTAOutcome
                isAdditionalTrack={isAdditionalTrack}
                upToAmount={upToAmount}
                name={name}
                isPartnerOutcome={isPartnerOutcome}
                investmentAmount={investmentAmount}
              />
            ))}
          {!isPartnerOutcome &&
            (showInquirySignInMethods ? (
              <InquiryGetStartedButton
                onClickResendMagicLink={onClickResendMagicLink}
                isAdditionalTrack={isAdditionalTrack}
              />
            ) : (
              <div className="InquiryOutcomeSubTextContainer">
                <InquiryCTAOutcomeButton
                  onClickResendMagicLink={onClickResendMagicLink}
                  isAdditionalTrack={isAdditionalTrack}
                />
              </div>
            ))}
          {showInquirySignInMethods ? (
            <div className="InquiryOutcomePrequalLegalNote">
              This prequalification is nonbinding and subject to Hometap's review of your application, including a
              third-party appraisal of your home.
            </div>
          ) : (
            <div>
              <div className="InquiryOutcomeLegalDivider" />
              <div className="InquiryOutcomeLegalNote">
                <strong>Please note:</strong>&nbsp;Your Investment Estimate is nonbinding and subject to Hometap’s
                review of your Application, including a third-party appraisal of your home.
              </div>
            </div>
          )}
        </div>
      </div>
      {showInquirySignInMethods ? (
        <div className="InquiryOutcomeSuccessfulInfo">
          <div className="InquiryOutcomeSuccessfulInfoFact">
            <div>
              <Icon name="icon-money-payment" size="2x" color="#25be8a" />
            </div>
            <div className="InquiryOutcomeSuccessfulInfoText">
              <strong>No debt or restrictions on how you use the funds,</strong> so you can meet your goals, stress-free
            </div>
          </div>
          <div className="InquiryOutcomeSuccessfulInfoFact">
            <div>
              <Icon name="icon-work-life" size="2x" color="#25be8a" />
            </div>
            <div className="InquiryOutcomeSuccessfulInfoText">
              <strong>No monthly payments,</strong> pre-payment penalties, or blackout periods — just settle anytime
              during the 10-year term
            </div>
          </div>
          <div className="InquiryOutcomeSuccessfulInfoFact">
            <div>
              <Icon name="icon-investment-manager" size="2x" color="#25be8a" />
            </div>
            <div className="InquiryOutcomeSuccessfulInfoText">
              <strong>Fast, simple, and transparent process,</strong> with a dedicated Investment Manager to answer your
              questions
            </div>
          </div>
        </div>
      ) : (
        <CardGroup
          className="InquiryOutcomeCardGroup"
          cardAlignment="center"
          cardSize={{ minWidth: 290 }}
          cardFit={isScreenSize('md') ? '100%' : '33%'}
          preventOrphans
        >
          <PointCard icon="check-filled" title="No monthly payments or interest over the life of the Investment." />
          <PointCard
            icon="check-filled"
            title="A fast, simple, and transparent Investment process, including a dedicated Investment Manager to answer all of your questions."
          ></PointCard>
          <PointCard
            icon="check-filled"
            title="Flexibility to settle your Investment anytime within the 10-year effective period — no prepayment penalties or blackout periods."
          />
        </CardGroup>
      )}

      <Paper theme="medium">
        <div className="InquiryResultsCarouselContainer">
          <TestimonialsCarousel />
        </div>
        <TrustPilot spacingTop={5} spacingBottom={73} />
      </Paper>

      {!showInquirySignInMethods && <InquiryOutcomeFAQ isAdditionalTrack={isAdditionalTrack} />}

      <AudacyPixel goalName={'formfill'} goalId={'42889'} />
    </div>
  );
};

export default InquiryOutcomeSuccessful;

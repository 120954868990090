import { useState } from 'react';
import { MuiTextInput, Button } from '@hometap/htco-components';

import { validateEmailSpelling } from 'utils/validateEmailSpelling';
import { emailValidator } from 'utils/validators';
import './EmailInputWithValidation.scss';

const EmailInputWithValidation = ({
  name,
  label,
  width,
  required,
  disabled,
  handleFieldChange,
  validator = emailValidator,
  ...props
}) => {
  const [emailSuggestion, setEmailSuggestion] = useState(false);
  const [error, setError] = useState();

  return (
    <div className="EmailInputWithValidation">
      <MuiTextInput
        name="email"
        label={label}
        type="email"
        inputMode="email"
        maxLength={254}
        width="100%"
        required
        validator={validator}
        disabled={disabled}
        {...props}
        error={props.error ? props.error : error}
        onBlur={(val, _, err) => {
          setEmailSuggestion(validateEmailSpelling(val));
          setError(err);
        }}
      />
      {emailSuggestion && (
        <div className="EmailSuggestionMessage">
          Did you mean {emailSuggestion}?
          <Button
            theme="link"
            onClick={() => {
              handleFieldChange(emailSuggestion, name);
              setEmailSuggestion(false);
              setError(undefined);
            }}
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmailInputWithValidation;

/**
 * Inspired by code from https://gist.github.com/gf3/761ca1469939057a7fa406bfc229f8ad
 */
// we first need a string distance algorithm
function sift4(s1, s2, maxOffset, maxDistance) {
  if (!s1 || !s1.length) {
    if (!s2) {
      return 0;
    }
    return s2.length;
  }

  if (!s2 || !s2.length) {
    return s1.length;
  }

  const l1 = s1.length;
  const l2 = s2.length;

  let c1 = 0; //cursor for string 1
  let c2 = 0; //cursor for string 2
  let lcss = 0; //largest common subsequence
  let local_cs = 0; //local common substring
  let trans = 0; //number of transpositions ('ab' vs 'ba')
  const offset_arr = []; //offset pair array, for computing the transpositions

  while (c1 < l1 && c2 < l2) {
    if (s1.charAt(c1) === s2.charAt(c2)) {
      local_cs++;
      let isTrans = false;
      //see if current match is a transposition
      let i = 0;
      while (i < offset_arr.length) {
        const ofs = offset_arr[i];
        if (c1 <= ofs.c1 || c2 <= ofs.c2) {
          // when two matches cross, the one considered a transposition is the one with the largest difference in offsets
          isTrans = Math.abs(c2 - c1) >= Math.abs(ofs.c2 - ofs.c1);
          if (isTrans) {
            trans++;
          } else if (!ofs.trans) {
            ofs.trans = true;
            trans++;
          }
          break;
        } else if (c1 > ofs.c2 && c2 > ofs.c1) {
          offset_arr.splice(i, 1);
        } else {
          i++;
        }
      }
      offset_arr.push({
        c1: c1,
        c2: c2,
        trans: isTrans,
      });
    } else {
      lcss += local_cs;
      local_cs = 0;
      if (c1 !== c2) {
        c1 = c2 = Math.min(c1, c2); //using min allows the computation of transpositions
      }
      //if matching characters are found, remove 1 from both cursors (they get incremented at the end of the loop)
      //so that we can have only one code block handling matches
      for (let i = 0; i < maxOffset && (c1 + i < l1 || c2 + i < l2); i++) {
        if (c1 + i < l1 && s1.charAt(c1 + i) === s2.charAt(c2)) {
          c1 += i - 1;
          c2--;
          break;
        }
        if (c2 + i < l2 && s1.charAt(c1) === s2.charAt(c2 + i)) {
          c1--;
          c2 += i - 1;
          break;
        }
      }
    }
    c1++;
    c2++;
    if (maxDistance) {
      const temporaryDistance = Math.max(c1, c2) - lcss + trans;
      if (temporaryDistance >= maxDistance) return Math.round(temporaryDistance);
    }
    // this covers the case where the last match is on the last token in list, so that it can compute transpositions correctly
    if (c1 >= l1 || c2 >= l2) {
      lcss += local_cs;
      local_cs = 0;
      c1 = c2 = Math.min(c1, c2);
    }
  }
  lcss += local_cs;
  return Math.round(Math.max(l1, l2) - lcss + trans); //add the cost of transpositions to the final result
}

// list of domains we want to include as suggestions
const DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'msn.com',
  'live.com',
  'outlook.com',
  'googlemail.com',
  'mail.com',
  'google.com',
  'verizon.net',
  'icloud.com',
  'protonmail.com',
  'zoho.com',
  'ymail.com',
  'comcast.net',
  'me.com',
  'hometap.com',
];

const HOSTS = [
  'gmail',
  'yahoo',
  'hotmail',
  'aol',
  'msn',
  'live',
  'outlook',
  'googlemail',
  'mail',
  'google',
  'verizon',
  'icloud',
  'protonmail',
  'zoho',
  'ymail',
  'comcast',
  'me',
  'hometap',
];

const TLDS = ['com', 'net', 'us', 'org', 'gov', 'ca'];

function findClosest(haystack, needle, threshold = 2) {
  const closest = haystack.reduce((prev, d) => {
    const distance = sift4(needle, d, 5, 13);
    const current = {
      distance,
      match: d,
    };

    if (!prev) {
      return current;
    }

    return current.distance < prev.distance ? current : prev;
  }, undefined);

  if (closest && closest.distance > threshold) {
    return undefined;
  }

  return closest;
}

/**
 * Validate e-mail address spelling based on a list of known domains
 * @param {string} email e-mail address to check spelling
 * @returns {string|undefined} returns e-mail suggestion or nothing if no suggestion
 */
export function validateEmailSpelling(email) {
  // get user and domain from address provided
  const match = /(\S+?@)(\S+?(\.\S{2,}|\S{3})?)$/u.exec(email);

  if (!match) {
    return undefined;
  }

  const [, user, domain, tld] = match;
  const host = tld ? domain.slice(0, -1 * tld.length) : undefined;

  // Check full domain
  if (DOMAINS.includes(domain)) {
    return undefined;
  }

  console.log(host);
  if (!host || host.length > 2) {
    const closestDomain = findClosest(DOMAINS, domain);

    if (closestDomain) {
      return `${user}${closestDomain.match}`;
    }
  }

  // Check host and top-level domains
  if (host) {
    const closestHost = host.length > 2 ? findClosest(HOSTS, host) : undefined;
    const strippedTld = tld[0] === '.' ? tld.slice(1) : tld;
    const threshold = domain.indexOf('.') >= 0 ? 2 : 1;
    const closestTld = findClosest(TLDS, strippedTld, threshold);

    if (!closestHost) {
      if (!closestTld) {
        return undefined;
      }

      const suggest = `${user}${host}.${closestTld.match}`;

      if (suggest === email) {
        return undefined;
      }

      return suggest;
    }
    if (!closestTld) {
      return `${user}${closestHost.match}${tld}`;
    }

    return `${user}${closestHost.match}.${closestTld.match}`;
  }

  return undefined;
}
